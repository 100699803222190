// src/components/Contact.js
import React from 'react';

const Contact = () => (
    <section className="contact">
      <h2>Contact</h2>
      <p>Email: <a href="mailto:hi@schooldays.xyz">hi@schooldays.xyz</a></p>
    </section>
  );

export default Contact;
