// src/App.js
import React from 'react';
import Introduction from './components/Introduction';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import './App.css';

const App = () => (
  <div>
    <Introduction />
    <Portfolio />
    <Contact />
  </div>
);

export default App;
