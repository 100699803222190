// src/components/Portfolio.js
import React from 'react';

const Portfolio = () => (
  <section className="portfolio">
    <h2>Projects</h2>
    <div className="portfolio-grid">

       <div className="portfolio-item">
        <h3>一價網｜比較各國同一商品價格</h3>
        <a href="https://premium.schooldays.xyz" target="_blank" rel="noopener noreferrer">premium.schooldays.xyz</a>
        <p>一個全球格價平台，幫助用戶了解不同國家的商品價格差異。</p>
      </div>
      <div className="portfolio-item">
        <h3>聖誕交換禮物抽獎工具</h3>
        <a href="https://christmas.schooldays.xyz" target="_blank" rel="noopener noreferrer">christmas.schooldays.xyz</a>
        <p>為聖誕節打造的抽獎工具，讓禮物交換更加有趣和方便。</p>
      </div>
        <div className="portfolio-item">
        <h3>車品網｜匿名留言給車主</h3>
        <a href="https://driver.schooldays.xyz" target="_blank" rel="noopener noreferrer">driver.schooldays.xyz</a>
        <p>一個允許用戶匿名留言給車主的平台，增進交通文化與溝通。</p>
      </div>
      <div className="portfolio-item">
        <h3>Gemini Pro Chat</h3>
        <a href="https://gemini.schooldays.xyz" target="_blank" rel="noopener noreferrer">gemini.schooldays.xyz</a>
        <p>免費及免VPN的Gemini Pro，讓香港用戶也可以自由使用AI。</p>
      </div>
      <div className="portfolio-item">
        <h3>Page Indexing Checker</h3>
        <a href="https://seo.schooldays.xyz" target="_blank" rel="noopener noreferrer">seo.schooldays.xyz</a>
        <p>A free tool to help you to keep your website up-to-date in Google search results.</p>
      </div>
    </div>
  </section>
);

export default Portfolio;
