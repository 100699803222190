// src/components/Introduction.js
import React from 'react';

const Introduction = () => (
    <section className="introduction">
      <h1>schooldays.xyz</h1>
      <p>Problem solving in its simplest form.</p>
    </section>
  );

export default Introduction;
